const AgeGate = (($) => {
    class AgeGate {
        
        constructor(node) {
            this.$gate = $(node);
            this.$message = this.$gate
                .find('.message');
            this.storage = this.getStorageSettings();

            this.$trigger = $('form', this.$gate);
        }

        getStorageSettings() {
            const settings = {
                key: 'curiowellness_agegate',
            };

            settings.expirationKey = `${settings.key}_expiration`;

            return settings;
        }

        showModal() {
            this.$gate.addClass('active'); return this;
        }

        hideModal() {
            this.hideMessage();

            this.$gate.removeClass('active');
        }

        hideMessage() {
            this.$message.slideUp(); return this;
        }

        showMessage() {
            this.$message.slideDown(); return this;
        }

        onSubmit(form) {
            form.preventDefault();
            const target = form.target;
            
            // Get the value of the 'dateofbirth' input field
            const dateOfBirthValue = $(target).find('input[name="dateofbirth"]').val();
        
            // Check if the 'dateofbirth' is valid and the user is 21 or older
            const isTwentyOneOrOlder = this.checkIfTwentyOneOrOlder(dateOfBirthValue);
          
            if (!isTwentyOneOrOlder) {
                this.showMessage(); 
                return;
            }
        
            // Check if the 'remember' checkbox is checked
            const doesRememberLogin = $(target).find('input[name="remember"]').is(':checked');
        
            // Store the choice (remember login or not)
            this.store(doesRememberLogin);
        
            // Hide the modal if the user is 21 or older
            this.hideModal();
            
            return this;
        }
        
        checkIfTwentyOneOrOlder(dateOfBirthValue) {
            // Parse the input date
            const birthDate = new Date(dateOfBirthValue);
            const today = new Date();
        
            // Calculate the age
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;  // Adjust age if the birthday hasn't occurred this year yet
            }
        
            return age >= 21;  // Return true if 21 or older, otherwise false
        }

        store(remember = false) {
            if (!remember) {
                this.setSessionStorage(); return;
            }

            this.setLocalStorage();

            return this;
        }

        verify() {

            if (this.getSessionStorage()) return true;

            if (!this.hasLocalStorageExpired()) return true;

            this.clearStorage();

            this.showModal();

            return false;
        }

        clearStorage() {
            window.sessionStorage.removeItem(this.storage.key);
            window.localStorage.removeItem(this.storage.key);
            window.localStorage.removeItem(this.storage.expirationKey);

            return this;
        }

        hasLocalStorageExpired() {
            const storage = this.getLocalStorage();

            if (!storage) return true;

            const curDate = new Date().getTime();
            const expirationDate = parseInt(storage.expiration, 10);

            return (curDate >= expirationDate);
        }

        getLocalStorage() {
            const toFetch = {
                key: window.localStorage.getItem(this.storage.key),
                expiration: window.localStorage.getItem(this.storage.expirationKey),
            };

            const isValid = Object.keys(toFetch).every((k) => toFetch[k]);

            return isValid
                ? toFetch
                : false;
        }

        setLocalStorage(removeSessionStorage = true) {
            window.localStorage.setItem(this.storage.key, 1);
            window.localStorage.setItem(this.storage.expirationKey, this.getStoreExpiration());

            if (!removeSessionStorage) return this;
            
            window.sessionStorage.removeItem(this.storage.key);

            return this;
        }

        getSessionStorage() {
            return window.sessionStorage.getItem(this.storage.key);
        }

        setSessionStorage(removeLocalStorage = true) {
            window.sessionStorage.setItem(this.storage.key, 1);

            if (!removeLocalStorage) return this;

            window.localStorage.removeItem(this.storage.key);
            window.localStorage.removeItem(this.storage.expirationKey);

            return this;
        }

        getStoreExpiration() {
            const date = new Date();

            date.setMonth(date.getMonth() + 1);

            return date.getTime();
        }
      

        init() {
            this.$trigger.on('submit', this.onSubmit.bind(this));        
            
            this.verify();
        }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new AgeGate(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
 
    AgeGate.init({
       selector: '.age-gate',
    });


 })(window.jQuery);
