
if('wp' in window && 'blocks' in wp) {

    wp.blocks.registerBlockVariation(
        'core/group',
        {
            name: 'cards-group',
            title: 'Cards Group',
            attributes: {
                className: "cards-group"
            }
        }
    );

    wp.blocks.registerBlockVariation(
        'core/group',
        {
            name: 'legacy-content',
            title: 'Legacy Content Group',
            attributes: {
                className: "legacy-content"
            }
        }
    );

    wp.blocks.registerBlockVariation(
        'core/group',
        {
            name: 'titled-group',
            title: 'Titled Group',
            attributes: {
                className: "titled-group"
            }
        }
    );
}
