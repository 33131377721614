const ReadMore = (($) => {
    class ReadMore {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
        this.$toggle = $('[data-read-more-collapsable]', this.$node);
        this.$content = $('[data-read-more-content]', this.$toggle);

        this.$toggleLink = $('[data-read-more-toggle]', this.$node);
        this.collapsed = this.$toggle.hasClass('collapsed');
        this.desiredCollapsed = this.collapsed;
      }

      onToggleClick(e) {
        e.preventDefault();
        this.$node.toggleClass('active');

        if(this.$node.hasClass('active')) {
            this.$node.attr('aria-expanded', 'true');
        } else {
            this.$node.attr('aria-expanded', 'false');
        }
      }

      isOverflown() {
        return this.$content[0].clientHeight > this.$toggle[0].clientHeight;
      }

      onResize() {
        if(this.onResizeTimeout) {
            clearTimeout(this.onResizeTimeout);
        }
        
        this.onResizeTimeout = setTimeout(() => {
            var isOverflown = this.isOverflown();
            
            this.$node.removeClass('active');

            if(isOverflown) {
                this.$toggleLink.show();
            } else {
                this.$toggleLink.hide();
            }
        }, 250);
      }

      init() {
        this.$toggleLink.on('click', this.onToggleClick.bind(this));
        $(window).on('resize', this.onResize.bind(this));
        this.onResize();
      }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new ReadMore(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
 
    ReadMore.init({
       selector: '[data-read-more]',
    });


 })(window.jQuery);