if('wp' in window && 'blocks' in wp) {

    heading_styles = [
        {
            name: 'h1-decorative',
            label: 'H1 Decorative'
        },
        {
            name: 'subsection-title',
            label: 'Subsection Title'
        }
    ];

    paragraph_styles = [
        {
            name: 'paragraph-large',
            label: 'Paragraph Large'
        }
    ]

    wp.blocks.registerBlockStyle( 'core/heading', heading_styles);
    wp.blocks.registerBlockStyle( 'core/paragraph', [...heading_styles, ...paragraph_styles]);

}


