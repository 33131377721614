if('wp' in window && 'blocks' in wp) {

    $styles = [
        {
            name: 'brand',
            label: 'Brand'
        },
        {
            name: 'brand-alt',
            label: 'Brand Alt',
            isDefault: true,
        },
        {
            name: 'brand-alt-200',
            label: 'Brand Alt Light'
        }
    ];

    wp.blocks.registerBlockStyle( 'acf/featured-links', $styles);

    wp.hooks.addFilter(
        'blocks.registerBlockType',
        'park/blocks',
        function( settings, name ) {
            $delete_mode = [
                'acf/featured-links',
            ];

            if ( $delete_mode.includes(name) ) {
                delete settings.supports.mode;
            }
            return settings;
        }
    );
}


