if('wp' in window && 'blocks' in wp) {

    $styles = [
        {
            name: 'brand',
            label: 'Brand',
            isDefault: true,
        },
        {
            name: 'brand-alt',
            label: 'Brand Alt'
        }
    ];

    wp.blocks.registerBlockStyle( 'acf/footer-large', $styles);
}

// const formButton = document.querySelector('.site-footer .gform_wrapper .gform_button');
// console.log(formButton);

// let oldValue = '';

// formButton.addEventListener("mouseover", function() {
//     oldValue = formButton.getAttribute('value');
//     formButton.setAttribute('value', 'Submit');
//     formButton.setAttribute('value', oldValue);

// });

