


const Accordion = (($) => {
   class Accordion {
     constructor(node) {
       this.node = node;
       this.$node = $(node);
       this.$button = $('[id^="button-accordion-"]', this.$node);
       this.$content = $('#' + this.$button.attr('aria-controls'), this.$node);
     }
 
     onAccordionClick(e) {
       this.toggleAccordion();
     }
 
     onAccordionKeypress(e) {
       if (e.keyCode === 13) {
         this.toggleAccordion();
       }    
     }
 
     toggleAccordion() {
       this.$content.slideToggle(250);
       this.$node.toggleClass('active');
       this.$button.attr('aria-expanded', this.$button.attr('aria-expanded') == 'false' ? 'true' : 'false');
     }
 
     init() {
       this.$button.on('click', this.onAccordionClick.bind(this))
     }
   }
 
   return {
      init({ selector, context } = {}) {
         return jQuery(selector, context).map((index, node) => {
            const module = new Accordion(node);
            module.init();
         });
       },
   };
 })(window.jQuery);

(($) => {
   let context = $(document);

   Accordion.init({
      selector: '[data-accordion]',
      context,
   });

})(window.jQuery);


