const ReceptorAnatomyGraphic = (function ReceptorAnatomyGraphic(window, $) {
    const role = 'data-receptor-anatomy';
    const Module = {
        btnRole: `${role}-btn`,
        contentRole: `${role}-content`,
        showOnLoadRole: `${role}-show-on-load`,
        pulseRole: `${role}-pulse`,
        activeClass: 'js-active',
        $window: $(window),
        getType($btn) {
            return $btn.attr(this.btnRole);
        },
        render(e) {
            e.preventDefault();
            
            const $btnElem = $(e.currentTarget);
            const type = this.getType($btnElem);

            $(`[${this.btnRole}="${type}"]`).toggleClass(this.activeClass);
            $(`[${this.contentRole}="${type}"]`).toggleClass(this.activeClass); 
        },
        showOnLoadElems() {
            $(`[${this.showOnLoadRole}]`).addClass('js-active');
        },
        togglePulse(e) {
            const shouldShowAnimation = (e.type === 'mouseout');

            if (shouldShowAnimation) {
                $(`[${this.btnRole}]`).attr(this.pulseRole, true); return;
            }

            $(`[${this.btnRole}]`).removeAttr(this.pulseRole);
        },
        setEventBindings() {
            this.$window.on('load', this.showOnLoadElems);

            $(`[${this.btnRole}]`).on('mouseover  mouseout', (e) => {
                this.render(e);
                this.togglePulse(e);
            });
        },
        setMethodBindings() {
            this.render = this.render.bind(this);
            this.showOnLoadElems = this.showOnLoadElems.bind(this);
            this.togglePulse = this.togglePulse.bind(this);
        },
        init() {
            this.setMethodBindings();
            this.setEventBindings();
        }
    };
    return Module;
}(window, window.jQuery));

ReceptorAnatomyGraphic.init();

export default ReceptorAnatomyGraphic;
