const PromoBar = (($) => {
    class PromoBar {
        constructor(node,overlay) {
            this.node = node;
            this.$node = $(node);
            this.$overlay = $(overlay);
            this.$closeButton = $('.close-promo-drawer', this.$node);
            this.$openButton = $('.open-promo-drawer', this.$node);
        }

        parseDate(dateStr) {
            const [year, month, day] = dateStr.split('-').map(Number);
            return new Date(year, month - 1, day);
        }

        getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }
        
        checkPromoBarCookie() {
            const PromoBar = this.getCookie('PromoBar');
            if (PromoBar === 'true') {
                this.hidePromoBar();
                console.log('promo-bar removed');
                
            } else {
                this.showPromoBar();
                this.setPromoBarCookie();
                console.log('promo-bar added');
            }
        }

        hidePromoBar() {
            this.$node.removeClass('active');
            this.$overlay.removeClass('active');
        }

        showPromoBar() {
            this.$node.addClass('active');
            this.$overlay.addClass('active');
        }

        setPromoBarCookie() {

            
            const today = new Date();
            const expirylength = today.getTime() + (7 * 24 * 60 * 60 * 1000); // days * hours * minutes * seconds * milloiseconds
            const expiry = new Date(expirylength);
            var secure = '';
            
            
            if (this.isSecure()) {
                secure = '; secure';
            }
            
            document.cookie = 'PromoBar=true; path=/; expires=' + expiry.toGMTString() + secure;
        }

        getCookie(name) {
            var re = new RegExp(name + "=([^;]+)"),
                value = re.exec(document.cookie);
            return (value != null) ? unescape(value[1]) : null;
        }

        isSecure() {
            return window.location.protocol === 'https:';
        }

        init() {
            //this.checkPromoBarCookie();
            this.hidePromoBar();

            this.$closeButton.on('click', () => {
                this.hidePromoBar();
            });

            this.$openButton.on('click', () => {
                this.showPromoBar();
            });

            this.$overlay.on('click', () => {
                this.hidePromoBar();
            });
            
            // Hide welcome mat on scroll past its bottom edge
            let initialScrollPosition = null;
    
            $(window).on('scroll', () => {
            
                if (initialScrollPosition === null && ($('.age-gate').length == 0 || !$('.age-gate').hasClass('active'))) {
                    initialScrollPosition = $(window).scrollTop();
                }
                
                const currentScrollPosition = $(window).scrollTop();
                
                if (initialScrollPosition !== null && currentScrollPosition > initialScrollPosition + 100) {
                    this.hidePromoBar();
                }
            });
        }
    }

    return {
        init({ selector,overlaySelector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new PromoBar(node, $(overlaySelector));
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);
    
     PromoBar.init({
         selector: '.promo-drawer',
         overlaySelector: '.promo-drawer-overlay',
     });
})(window.jQuery);
