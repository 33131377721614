if('wp' in window && 'blocks' in wp) {

    if('wp' in window && 'blocks' in wp) {

        $styles = [
            {
                name: 'white',
                label: 'White'
            },
            {
                name: 'brand',
                label: 'Brand'
            },
            {
                name: 'brand-100',
                label: 'Brand Light',
            },
            {
                name: 'brand-alt',
                label: 'Brand Alt'
            },
            {
                name: 'brand-alt-100',
                label: 'Brand Alt Light'
            },
            {
                name: 'gray',
                label: 'Gray'
            },
            {
                name: 'accent-100',
                label: 'Orange Light'
            },
            {
                name: 'red-100',
                label: 'Red Light'
            },
            {
                name: 'purple-100',
                label: 'Purple Light'
            },
            {
                name: 'green-100',
                label: 'Green Light'
            },
        ];
    
        wp.blocks.registerBlockStyle( 'acf/container', $styles);
    }
}

