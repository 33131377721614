if('wp' in window && 'blocks' in wp) {

    let styles = [
        {
            name: 'theme',
            label: 'Theme',
            isDefault: true
        },
        {
            name: 'theme-mobile-stack',
            label: 'Theme Mobile Stack',
            isDefault: true
        },
    ];

    wp.blocks.registerBlockStyle( 'core/table', styles);

}


jQuery(function() {
    jQuery(".wp-block-table").each(function() {
        checkScrollPos($(this));
    });

    jQuery(window).on("resize", function() {
        jQuery(".wp-block-table").each(function() {	
            checkScrollPos($(this));
        });
    });

    jQuery(".wp-block-table").on("scroll", function() {	
        checkScrollPos($(this));

    });     
});

function checkScrollPos($container) {
    var $this = $container,
        scrollLeft = $this.scrollLeft(),
        maxScrollWidth = $this.innerWidth(),
        maxScrollAmt = $this.find("table").prop('scrollWidth') - maxScrollWidth;
    
    if(maxScrollWidth < $this[0].scrollWidth) {
        $this.closest(".wp-block-table").addClass("scroll-fade");
    } else {
        $this.closest(".wp-block-table").removeClass("scroll-fade");
    }

    if (scrollLeft >= (maxScrollAmt -1)) {
        $this.closest(".wp-block-table").addClass("scrolled-right");
    } else {
        $this.closest(".wp-block-table").removeClass("scrolled-right");
    }

    if (scrollLeft < 1) {
        $this.closest(".wp-block-table").addClass("scrolled-left");
    } else {
        $this.closest(".wp-block-table").removeClass("scrolled-left");
    }
}