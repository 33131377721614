
if('wp' in window && 'blocks' in wp && 'lodash' in window) {


    wp.blocks.registerBlockVariation(
        'stackable/spacer',
        {
            name: 'standard',
            title: 'Standard Spacer 60/60/100',
            attributes: {
                height: 100,
                heightTablet: 60,
                heightMobile: 60
            }

        }
    );


}
