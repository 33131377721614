

if('wp' in window && 'blocks' in wp) {
	wp.hooks.addFilter(
		'basejump.registerLayouts',
		'curio/layouts',
		function(layouts) {
			return [
				...layouts,
				{
					title: '5 Columns',
					name: '5-columns',
					icon:  '5 columns'
				},
				{
					title: '100%',
					name: '100',
					icon:  '100%'
				},
				{
					title: '50/50 with Margins',
					name: '50-50-margin',
					icon:  '50/50 Margin'
				},
			]
		}
	)
}