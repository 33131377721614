if('wp' in window && 'blocks' in wp) {

    $styles = [
        {
            name: 'brand',
            label: 'Brand',
            isDefault: true
        },
        {
            name: 'brand-alt',
            label: 'Brand Alt'
        },
        {
            name: 'brand-alt-200',
            label: 'Brand Alt Light'
        }
    ];

    wp.blocks.registerBlockStyle( 'acf/student-quote', $styles);

}
