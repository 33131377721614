const WelcomeMat = (($) => {
    class WelcomeMat {
        constructor(node) {
            this.node = node;
            this.$node = $(node);
            this.$button = $('.dismiss-notification', this.$node);

            // Check for start and end date in URL parameters
            const urlStart = this.getUrlParameter('start');
            const urlEnd = this.getUrlParameter('end');
            
            this.start = urlStart ? this.parseDate(urlStart) : this.parseDate(this.$node.data('welcome-start'));
            this.end = urlEnd ? this.parseDate(urlEnd) : this.parseDate(this.$node.data('welcome-end'));
           
            this.repeat = parseInt(this.$node.data('welcome-repeat'));
        }

        parseDate(dateStr) {
            const [year, month, day] = dateStr.split('-').map(Number);
            return new Date(year, month - 1, day);
        }

        isTargetState(state) {
            const targetStates = [
                'Missouri', 
                // Add adjescent states
                'Iowa', 'Illinois', 'Kentucky', 'Tennessee', 'Arkansas', 'Oklahoma', 'Kansas', 'Nebraska'
            ];
            return targetStates.includes(state);
        }

        getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        fetchAndCheckUserState() {
            // Check if a state is set in the URL
            const urlState = this.getUrlParameter('state');
            if (urlState) {
                return Promise.resolve(this.isTargetState(urlState));
            }
    
            // Fetch state from API if not set in URL
            return fetch('https://ipapi.co/json/')
                .then(response => response.json())
                .then(data => this.isTargetState(data.region))
                .catch(error => {
                    console.error('Error fetching location via ip-api:', error);
                    return false;
                });
        }
        
        checkWelcomeMatCookie() {
            const welcomeMat = this.getCookie('welcomeMat');
            
            if (welcomeMat === 'true') {
                this.$node.removeClass('active');
            } else {
                this.fetchAndCheckUserState().then(isInTargetState => {
                   
                    if (isInTargetState) {
                        const today = new Date();
                        
                        if (today >= this.start && today <= this.end) {
                            this.$node.addClass('active');
                            window.scrollTo(0, 0); // Scroll to the top of the browser window

                            // Set the cookie on landing
                            this.setWelcomeMatCookie();
                        }
                    }
                });
            }

        }

        setWelcomeMatCookie() {
            const today = new Date();
            const expiry = new Date(today.getTime() + this.repeat * 24 * 3600 * 1000);
            var secure = '';
            
            if (this.isSecure()) {
                secure = '; secure';
            }
            
            document.cookie = 'welcomeMat=true; path=/; expires=' + expiry.toGMTString() + secure;
        }

        getCookie(name) {
            var re = new RegExp(name + "=([^;]+)"),
                value = re.exec(document.cookie);
            return (value != null) ? unescape(value[1]) : null;
        }

        isSecure() {
            return window.location.protocol === 'https:';
        }

        init() {
            this.checkWelcomeMatCookie();
            
            this.$button.on('click', () => {
                this.$node.removeClass('active');
            });
            
            // Hide welcome mat on scroll past its bottom edge
            $(window).on('scroll', () => {
                const welcomeMatBottom = this.$node.offset().top + this.$node.outerHeight();
                const scrollPosition = $(window).scrollTop() + $(window).height();
                
                if (scrollPosition > welcomeMatBottom * 2) {
                    this.$node.removeClass('active');
                }
            });
        }
    }

    return {
        init({ attribute, value } = {}) {
            return jQuery(`[data-${attribute}="${value}"]`).map((index, node) => {
                const module = new WelcomeMat(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

     WelcomeMat.init({
         attribute: 'welcome',
         value: 'true',
     });
})(window.jQuery);
