// patched in 2019-11 fix non-generating indexed content
var PARK = {
	init: function(){
		if (jQuery('.indexed-content').length == 0) {
			return false;
		}
		var indexed = [];
		jQuery('.indexed-content h2').each(function(index, heading){
			heading = jQuery(heading);
			indexed[index] = heading.text();
			heading.attr('id','indexed-section-' + index);
		});
		if (indexed.length > 0) {
			if (jQuery('.index_jumper').length > 0) {
				var listContainer = jQuery('.index_jumper'),
					half = Math.ceil(indexed.length / 2);
				listContainer.append('<ul class="half"></ul><ul class="half"></ul>');
				var lists = listContainer.find('ul.half');
				jQuery(indexed).each(function(index, item){
					var target = 0;
					if (index >= half) {
						target = 1;
					}
					jQuery(lists[target]).append('<li class="py-2 is-style-cta-right"><a href="#indexed-section-' + index + '" class="scroll_page">' + item + '</a></li>');
				});
				// jQuery(".scroll_page").click(Site.scrollPage);

				listContainer.addClass('pb-4');
			} 
			// else if (jQuery('.section_select').length > 0) {
			// 	var selectList = jQuery('select.selectbox');
			// 	jQuery(indexed).each(function(index, item){
			// 		var target = 0;
			// 		if (index >= half) {
			// 			target = 1;
			// 		}
			// 		jQuery(selectList).append('<option value="#indexed-section-' + index + '">' + item + '</option>');
			// 	});
			// 	jQuery(".ar_jumper .selectbox").selectBox({ links: true });
			// }
		}
	}
};
jQuery(document).ready(function() {
	PARK.init();
});

// <div class="wp-block-acf-links alignfull relative my-4 ">
    
// 	<div class="-mx-4">
// 		<ul class="grid md:grid-cols-2 gap-y-4 gap-x-8 list-none justify-left">
			
			// <li class=" wp-block-navigation-item is-style-cta-right wp-block-navigation-link"><a class="wp-block-navigation-item__content" href="#"><span class="wp-block-navigation-item__label">Student Support &amp; Counseling</span></a></li>

			// <li class=" wp-block-navigation-item is-style-cta-right wp-block-navigation-link"><a class="wp-block-navigation-item__content" href="#"><span class="wp-block-navigation-item__label">Park Connect</span></a></li>

// 		</ul>
// 	</div>
	
// </div>