const ProductTableShim = (($) => {

    class ProductTableShim {

      onResize() {
        // console.log('on resize');
        if(this.onResizeTimeout) {
            clearTimeout(this.onResizeTimeout);
        }
        
        this.onResizeTimeout = setTimeout(() => {

            var numPosts = $('.product-flower-grid > div:not(.product-table-shim)').length;
            var numberOfColumns = 0;
            var squaresToAdd = 0;
            
            var screenWidth = window.innerWidth;
            var squaresToAdd = 0;
    
            if (screenWidth >= 1280) {
                if((numPosts % 5) != 0){
                    if((numPosts % 5) != 0){
                        squaresToAdd = 5 - (numPosts % 5);
                    }
                }
            } else if (screenWidth >= 1024) {
                if((numPosts % 4) != 0){
                    squaresToAdd = 4 - (numPosts % 4);
                }
            } else if (screenWidth >= 768) {
                if((numPosts % 3) != 0){
                    squaresToAdd = 3 - (numPosts % 3);
                }
            } else if (screenWidth >= 600) {
                if((numPosts % 2) != 0){
                    squaresToAdd = 2 - (numPosts % 2);
                }
            } else {
                squaresToAdd = 0;
            }
            $(".product-table-shim").hide();
    
            for( let i = 0; i < squaresToAdd; i++) {
                $(".product-table-shim").eq(i).show();
            }
        }, 250);
      }

      init() {
        $(window).on('resize', this.onResize.bind(this));
        this.onResize();
      }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new ProductTableShim(node);
             module.init();
          });
        },
    };
  })(window.jQuery);


  (($) => {
 
    ProductTableShim.init({
       selector: '[data-js-product-table-shim]',
    });


 })(window.jQuery);