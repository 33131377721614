if('wp' in window && 'blocks' in wp) {
    var el = wp.element.createElement;
    const styles = [
        {
            name: 'brand',
            label: 'Brand'
        },
        {
            name: 'brand-alt',
            label: 'Brand Alt'
        },
        {
            name: 'white',
            label: 'White'
        },
    ];

    wp.blocks.registerBlockStyle( 'acf/card',  styles );
}


