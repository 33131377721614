export default class Geolocate {
    constructor() {
        if(!window.GMaps) {
            return;
        }
        
        this.latitude = null;
        this.longitude = null;
        this.geolocate = window.GMaps.geolocate.bind(this);
        this.storageKey = 'curiowellness_geolocation';
    }

    attempt(successCb) {
        const storage = this.getStorage();

        if (storage) {
            successCb(storage); return;
        }

        this.geolocate({
            success: ({ coords }) => {
                this.latitude = coords.latitude;
                this.longitude = coords.longitude;
                const infoFromLatLng = this.getInfoFromLatLng(coords);

                infoFromLatLng.done((response) => (
                    this.onInfoFromLatLngSuccess(response, successCb)
                ));
            },
            error: () => {},
        });
    }

    onInfoFromLatLngSuccess(response, successCb) {
        const results = JSON.parse(response);
        
        if (!results) return;

        this.store(results);

        successCb(results);
    }

    getStorage() {
        const storage = window.sessionStorage.getItem(this.storageKey);

        return storage
            ? JSON.parse(storage)
            : null;
    }

    store(results) {
        window.sessionStorage.setItem(this.storageKey, JSON.stringify(results));
    }

    getInfoFromLatLng(coords) {
        return $.ajax({
            method: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: {
                action: 'product_map_geocode',
                params: coords,
            },
        });
    }
}
