const DropdownCheckboxAll = (($) => {
    class DropdownCheckboxAll {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
        this.$form = this.$node.closest('form');
        this.$target = this.$node.attr('data-all');
      }

      onTriggerClick(e) {
        if (this.$node.prop('checked')) {
          this.$form.find('input[name="'+this.$target+'"]').prop('checked', false);
        }
      }
      
      init() {
        this.$node.on('click', this.onTriggerClick.bind(this));
      }
    }
  
    return {
       init({ selector } = {}) {
          return jQuery(selector).map((index, node) => {
             const module = new DropdownCheckboxAll(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    DropdownCheckboxAll.init({
       selector: '[data-all]',
    });

    $(document).on('ajaxComplete', ()=>{
      DropdownCheckboxAll.init({
        selector: '[data-dropdown="false"]',
      });
    })
 })(window.jQuery);