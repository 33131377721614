const Tabs = (($) => {
  class Tabs {
    constructor(node) {
      this.node = node;
      this.$node = $(node);
      this.$node.attr('data-tabs', true);
      this.tabGroup = this.$node.data('tab-group');
      this.$button = $('[data-tab-id]', this.$node).filter((index, button)=>{
        return $(button).closest('[data-tabs]').is(this.$node);
      });
    }

    onTabClick(e) {
        e.stopPropagation();
        let $button = $(e.currentTarget);
        let $content = $('#' + $button.attr('aria-controls'), this.$node);

        if((window.matchMedia('(min-width: 1024px)').matches && this.$node.attr('data-desktop-accordion') !== 'true') || this.$node.attr('data-mobile-accordion') !== 'true' ) {
          $('.active', this.$node).removeClass('active');
        }

        this.$node.toggleClass('active');
        $button.toggleClass('active');
        $content.toggleClass('active');
        $content.find('[data-slick]').slick('reinit');
        $(window).trigger('arrive');
     }

    reset() {
      if(this.$node.attr('data-desktop-accordion') !== 'true') {
        this.closeAll();
        let first_button = this.$button.first();
        first_button.addClass('active');

        $('[data-tab-id^="' + first_button.attr('data-tab-id') + '"]', this.$node).addClass('active');
        $('#' + $(first_button).attr('aria-controls'), this.$node).addClass('active');
      }
    }

    closeAll() {
      this.$button.removeClass('active');
      this.$button.each((index, $button)=> {
        $('#' + $($button).attr('aria-controls'), this.$node).removeClass('active');
      });
    }

    anchorInit() {
      let anchor = window.location.hash.substring(1);
      let tab = $('[data-tab-id="'+anchor+'"]', this.$node);
      if(tab.length){
        setTimeout(()=>{
          this.closeAll();
          tab.trigger('click');
        }, 100);
      }

    }

    init() {
      this.$button.on('click', this.onTabClick.bind(this));
      window.matchMedia('(min-width: 1024px)').onchange = (e)=>{
        if(e.matches) {
          this.reset();
        }
      };
      this.anchorInit();
    }
  }

  return {
     init({ selector, context } = {}) {

        return jQuery(selector, context).map((index, node) => {
           const module = new Tabs(node);
           module.init();
        });
      },
  };
})(window.jQuery);

(($) => {
  let context = $(document);

  Tabs.init({
     selector: '[data-tabs]',
     context,
  });

  $(document).on('ajaxComplete init-tabs', ()=>{
    Tabs.init({
      selector: '[data-tabs="false"]',
      context,
    });
  })

})(window.jQuery);

export default Tabs;