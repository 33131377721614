export default Object.create({
    lat: null,
    lng: null,
    title: null,
    address: {
        address_one: null,
        address_two: null,
        city: null,
        state: null,
        zip: null,
    },
    icon: null,
    details: {
        post_id: null,
        phone: null,
        type: null,
        website: null,
    },
    infoWindow: null,
    click: null,
});
