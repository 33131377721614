const Dropdown = (($) => {
    class Dropdown {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
        this.$node.attr('data-dropdown', true);
        this.$label = $('[data-dropdown-label]', this.$node);
        this.$trigger = $('[data-dropdown-trigger]', this.$node);
        this.$items = $('[data-dropdown-items]', this.$node);
        this.$buttons = $('[data-dropdown-item]', this.$node);
      }
      
      close() {
        this.$node.removeClass('active');
        this.$items.removeClass('active');
        this.$trigger.removeClass('active');
      }

      toggle() {
        let $expanded = this.$trigger.attr('aria-expanded') === 'false' ? 'true' : 'false';
        this.$trigger.attr('aria-expanded', $expanded);

        this.$node.toggleClass('active');
        this.$trigger.toggleClass('active');
        this.$items.toggleClass('active');
      }

      onTriggerClick(e) {
        this.toggle();
      }
      
      onOutsideClick(e) {
        if($(e.target).closest(this.$items).length === 0 && $(e.target).closest(this.$trigger).length === 0) {
          this.close();
        }
      }

      onButtonClick(e) {
        let $target = $(e.currentTarget);
        this.$buttons.removeClass('active');
        $target.addClass('active');
        let label = $target.data('dropdown-item');
        this.$label.html(label);
        this.close()
      }

      init() {
        this.$trigger.on('click mouseover', this.onTriggerClick.bind(this));        
        this.$buttons.on('click ', this.onButtonClick.bind(this));
        $(document).on('click mouseover',  this.onOutsideClick.bind(this))
      }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new Dropdown(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    Dropdown.init({
       selector: '[data-dropdown]',
    });

    $(document).on('ajaxComplete', ()=>{
      Dropdown.init({
        selector: '[data-dropdown="false"]',
      });
    })
 })(window.jQuery);