if('wp' in window && 'blocks' in wp) {
    wp.blocks.registerBlockStyle( 'acf/main-navigation', [ 
        {
            name: 'default',
            label: 'Default',
            isDefault: true,
        },
        {
            name: 'inverted',
            label: 'Inverted',
        },
    ]);
}

const navBtn = document.querySelector("header.main-navigation-block button.mobile-menu-button");
const navMenu = document.querySelector("header.main-navigation-block");
if(navBtn) {
    navBtn.addEventListener("click", () => {
        navMenu.classList.toggle("nav_open");
    });
}

const searchBtnOpen = document.querySelector(".search-wrapper button.search-open");
const searchBtnClose = document.querySelector(".search-wrapper button.search-close");
const searchMenu = document.querySelector(".search-wrapper .search-container");
const searchNav = document.querySelector("#nav_right");

if(searchBtnOpen) {
    searchBtnOpen.addEventListener("click", (event) => {
        event.preventDefault();
        searchMenu.classList.toggle("search_open");
        searchNav.classList.toggle("search_nav_open");
        setTimeout(function(){
            searchBtnClose.classList.toggle("search_open");
        }, 150);
    });
    searchBtnClose.addEventListener("click", (event) => {
        event.preventDefault();
        searchBtnClose.classList.toggle("search_open");
        setTimeout(function(){
            searchMenu.classList.toggle("search_open");
            searchNav.classList.toggle("search_nav_open");
        }, 50);
    });
}

if(typeof stickyNavDisable === 'undefined'){
    window.onscroll = function() {scrollFunction()};   
}

const navClasses = [
    'sticky',
    'z-30',
    'mt-0',
];

const navContainerClasses = [
    'xl:!px-0',
    '2xl:!px-0'
];

const navInnerContainerClasses = [
    'xl:bg-green-dark',
    'xl:w-auto',
    'xl:inline-flex',
];

const navInnerContainer = document.getElementById("main_nav_inner_container");

function scrollFunction() {
    let width = screen.width;
    let stickyAtPx = 0;
    const welcomeMat = document.querySelector('.welcome-mat[data-welcome="true"]');

    if(width >= 768){
        stickyAtPx = 300;
    } else {
        stickyAtPx = 100;
    }

    if (welcomeMat && welcomeMat.classList.contains('active')) {
        stickyAtPx += welcomeMat.offsetHeight;
    }

    if (document.body.scrollTop > stickyAtPx || document.documentElement.scrollTop > stickyAtPx) {
        document.getElementById('main_nav').classList.add('sticky-nav');
        setTimeout( function(){navInnerContainer.classList.add('transition-all');}, 1);

    } else {
        document.getElementById('main_nav').classList.remove('sticky-nav');
        navInnerContainer.classList.remove('transition-all');

    }
}

const stickyButton = document.getElementById("sticky-extend");
const stickyIconOpen = document.getElementById("sticky-icon-open");
const stickyIconClose = document.getElementById("sticky-icon-close");
const navLeft = document.getElementById("nav_left");
const navRight = document.getElementById("nav_right");

if(stickyButton) {
    stickyButton.addEventListener("click", (event) => {
        event.preventDefault();
        navInnerContainer.classList.toggle("sticky-open");
        navInnerContainer.classList.toggle("sticky-closed");
        stickyIconOpen.classList.toggle("hidden");
        stickyIconClose.classList.toggle("hidden");
    });
}

function is_touch_enabled() {
    return ( 'ontouchstart' in window ) ||
        ( navigator.maxTouchPoints > 0 ) ||
        ( navigator.msMaxTouchPoints > 0 );
}

if(is_touch_enabled()) {
    searches = document.getElementsByClassName("searchBox");

    for (var i = 0; i < searches.length; i++) {
        searches[i].classList.add('touchYes');
    }

} else {
    searches = document.getElementsByClassName("searchBox");
    for (var i = 0; i < searches.length; i++) {
        searches[i].classList.add('touchNo');
    }
}
