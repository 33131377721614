if ("wp" in window && "blocks" in wp) {
    wp.blocks.registerBlockVariation("core/columns", {
        name: "sidebar-right",
        title: "Sidebar Right",
        icon: wp.element.createElement(
            "svg",
            {
                viewBox: "0 0 48 48",
                width: "48",
                height: "48",
            },
            wp.element.createElement("path", {
                fillRule: "evenodd",
                clipRule: "evenodd",
                d: "M39 12C40.1046 12 41 12.8954 41 14V34C41 35.1046 40.1046 36 39 36H9C7.89543 36 7 35.1046 7 34V14C7 12.8954 7.89543 12 9 12H39ZM39 34V14H30V34H39ZM28 34H9V14H28V34Z",
            })
        ),
        innerBlocks: [
            ["core/column", { width: "70%" }],
            ["core/column", { width: "5%", className: "invisible !my-0" }],
            ["core/column", { width: "25%" }],
        ],
    });

    wp.blocks.registerBlockVariation("core/columns", {
        name: "sidebar-left",
        title: "Sidebar Left",
        icon: wp.element.createElement(
            "svg",
            {
                viewBox: "0 0 48 48",
                width: "48",
                height: "48",
            },
            wp.element.createElement("path", {
                fillRule: "evenodd",
                clipRule: "evenodd",
                d: "M39 12C40.1046 12 41 12.8954 41 14V34C41 35.1046 40.1046 36 39 36H9C7.89543 36 7 35.1046 7 34V14C7 12.8954 7.89543 12 9 12H39ZM39 34V14H30V34H39ZM28 34H9V14H28V34Z",
            })
        ),
        innerBlocks: [
            ["core/column", { width: "25%" }],
            ["core/column", { width: "5%", className: "invisible !my-0" }],
            ["core/column", { width: "70%" }],
        ],
    });
}
