if('wp' in window && 'blocks' in wp) {

    $styles = [
        {
            name: 'link-secondary',
            label: 'Secondary'
        },
        {
            name: 'link-tertiary',
            label: 'Tertiary'
        },
        {
            name: 'btn-primary',
            label: 'Primary Button'
        },
        {
            name: 'btn-secondary',
            label: 'Secondary Button'
        },
        {
            name: 'cta-right',
            label: 'Arrow Right'
        },
        {
            name: 'cta-left',
            label: 'Arrow Left'
        },
        {
            name: 'cta-right-secondary',
            label: 'Arrow Right Secondary'
        },
        {
            name: 'cta-left-secondary',
            label: 'Arrow Left Secondary'
        },
        {
            name: 'cta-right-tertiary',
            label: 'Arrow Right Tertiary'
        },
        {
            name: 'cta-left-tertiary',
            label: 'Arrow Left Tertiary'
        }
    ];

    wp.blocks.registerBlockStyle( 'core/navigation-link', $styles);

    wp.hooks.addFilter(
        'blocks.registerBlockType',
        'park/blocks',
        function( settings, name ) {
            $delete_mode = [
                'acf/links',
            ];

            if ( $delete_mode.includes(name) ) {
                delete settings.supports.mode;
            }
            return settings;
        }
    );
}


