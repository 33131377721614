if('wp' in window && 'blocks' in wp) {

    if('wp' in window && 'blocks' in wp) {

        $styles = [
            {
                name: 'white',
                label: 'White'
            },
            {
                name: 'brand-100',
                label: 'Brand',
            },
            {
                name: 'brand-alt-100',
                label: 'Brand Alt'
            },
            {
                name: 'gray',
                label: 'Gray'
            },
            {
                name: 'accent-100',
                label: 'Orange'
            },
            {
                name: 'red-100',
                label: 'Red'
            },
            {
                name: 'purple-100',
                label: 'Purple'
            },
            {
                name: 'green-100',
                label: 'Green'
            },
        ];
    
        wp.blocks.registerBlockStyle( 'acf/section-50-50', $styles);
    }
}

