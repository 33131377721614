if('wp' in window && 'blocks' in wp) {

    $heading_styles = [{
        name: 'default',
        label: 'Default',
        isDefault: true,
    },
    {
        name: 'h1-special',
        label: 'H1 Special'
    },
    {
        name: 'h1',
        label: 'H1',
    },
    {
        name: 'h2',
        label: 'H2',
    },
    {
        name: 'h3',
        label: 'H3',
    },
    {
        name: 'h4',
        label: 'H4',
    },
    {
        name: 'h5',
        label: 'H5',
    },
    {
        name: 'h6',
        label: 'H6',
    }];

    wp.blocks.registerBlockStyle( 'core/heading', $heading_styles);
    wp.blocks.registerBlockStyle( 'core/paragraph', $heading_styles);

}


