/**
 * @file
 * Provides MicroModal loader.
 */

(($, context) => {
    //Defines behavior of media embeds, and keyboard events
    class ModalBehavior {
        constructor(node) {
            this.node = node;
            this.$node = $(node);
            this.$close = $("[data-micromodal-close]", this.$node);
            this.$trigger = $("[data-micromodal-trigger]");
        }

        setEventBindings() {
            MicroModal.init({
                onShow: (modal) => {
                    $("[data-slick]").trigger("slick-reinit");
                },
                onClose: this.stopMediaEmbed.bind(this),
            });

            if (this.$node.data("micromodal-init-open") == true) {
                MicroModal.show(this.$node.prop("id"));
            }

            this.$close.on("click", this.stopMediaEmbed.bind(this));
            this.$trigger.on("keydown", (event) => {
                var keycode = event.keyCode ? event.keyCode : event.which;
                if (keycode == "13" || keycode == "32") {
                    event.preventDefault();
                    let modalId = $(event.currentTarget).data(
                        "micromodal-trigger"
                    );
                    MicroModal.show(modalId);
                }
            });

            $("a").on("click", (e) => {
                let target = $(e.currentTarget);
                let href = target.attr("href");
                if (
                    href.charAt(0) === "#" &&
                    href.length > 1 &&
                    $(href).is("[data-micromodal]")
                ) {
                    e.preventDefault();
                    href = href.substr(1);
                    MicroModal.show(href);
                }
            });
        }

        stopMediaEmbed() {
            // best way I could find to stop the embeded video players
            let $embed = this.$node.find("iframe");
            $embed.clone().appendTo($embed.parent());
            $embed.remove();
        }

        consoleModalIds() {
            console.log(`Modal ID: #${this.node.id}`);
        }

        init() {
            this.setEventBindings();
            this.consoleModalIds();
        }
    }

    MicroModal.init();

    $("[data-micromodal]", context).map((index, node) => {
        new ModalBehavior(node, context).init();
    });
})(jQuery, jQuery(document));
