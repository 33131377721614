import Tabs from '../../../../basejump/src/js/components/tabs.js';

const Filter = (($) => {
    class Filter {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
        this.$node.attr('data-ajax-posts', true);
        this.$form = $('form', this.$node);
        this.$results = $('[data-ajax-results]', this.$node);
        this.$activeFilters = $('[data-ajax-active-filters]', this.$node);
        this.$loadingIcon = $('[data-ajax-loading]', this.$node);
        this.$pagination = $('[data-page]', this.$node);
        this.xhr = null;
      }

      init() {
        this.updateUrl();
        this.updateActiveFilters();
        window.onpopstate = this.onPopState.bind(this);
        this.$form.on('submit', this.onFormChange.bind(this));
        this.$form.find('input[type="radio"], input[type="checkbox"], input[type="select"]').on('change', this.onFormChange.bind(this));
        this.$activeFilters.on('click', '[data-ajax-clear]', this.clearFilters.bind(this));
        this.$pagination.on('click', this.onPageClick.bind(this));
      }

      onPageClick(event) {
        event.preventDefault();
        let $target = $(event.currentTarget);
        let page = $target.data('page');
        let data = this.getFormData();
        data.page = page;

        let params = new URLSearchParams(data);
        this.updateUrl(params);
        this.ajaxPosts(data);
      }

      onFormChange(event){
        event.preventDefault();
        let data = this.getFormData();
        this.updateUrl();
        this.ajaxPosts(data);
        this.updateActiveFilters();
      }

      onPopState(event) {
        let state = event.state;
        let params = new URLSearchParams(state);
        this.populateForm(params);
        let data = this.getFormData();
        data.page = params.get('page') ?? 0;
        this.ajaxPosts(data);
        this.updateActiveFilters();
      }

      ajaxPosts(data){
        //todo change this to some type of loading
        this.$results.html('');
        this.$loadingIcon.removeClass('hidden');
        $('#filter-results').addClass('hidden');
        
        const args = {
          type: 'POST',
          url: '/wp-admin/admin-ajax.php',
          dataType: 'html',
          data: {
            action: this.$form.attr('data-action'),
            ...data
          },
          success: this.success.bind(this)
        }

        if(this.xhr) {
          this.xhr.abort();
        }
        this.xhr = $.ajax(args)
      }

      success(result) {
        this.$node.trigger('ajax_success');
        this.$results.html(result);
        this.$loadingIcon.addClass('hidden');

        // this.animateTop();
        var numResults = $('.product', this.$results).length;
        $('#filter-results').removeClass('hidden').html(numResults + ' Results');

        this.$pagination = $('[data-page]', this.$node);
        this.$pagination.on('click', this.onPageClick.bind(this));
      }

      updateActiveFilters() {
        const labels = this.getActiveFilters();
        const html = labels ? '<div>Filtered by ' + labels + ' | <button class="text-link" data-ajax-clear>Clear Filters</button></div>' : '';
        this.$activeFilters.html(html);
      }

      getFormData() {
        const formData = new FormData(this.$form[0]);
        const entries = formData.entries();
        const data = Object.fromEntries(entries);
        return data;
      }

      getActiveFilters(){
        const formData = new FormData(this.$form[0]);
        const filters = Array.from(formData.entries());
        const labels = filters.map((filter)=>{
          const [key, value] = filter;

          const $field = $('[name="' + key + '"]', this.$node).filter((index, field)=> {
            return $(field).val() === value;
          });

          const field_id = $field.attr('id');
          const fieldType = this.getFieldType($field[0]);

          if(fieldType === 'radio' && value !== 'all') {
            return $('[for="' + field_id + '"]').text();
          }

          if(['text', 'hidden'].includes(fieldType)) {
            return value;
          }

          return false
        });

        return labels.filter(Boolean).join(', ');
      }

      updateUrl(params) {
        if(typeof params === 'undefined') {
          const formData = new FormData(this.$form[0]);
          params = new URLSearchParams(formData);
        }

        for (const element of Array.from(params)) {
            const [key, value] = element;
            if (!value || value == 'all') {
                params.delete(key);
            }
        }

        params = params.toString();
        const query = (params) ? '?' + params : '';
        window.history.pushState(params, '',  window.location.pathname + query);
      }

      populateForm(params) {
        let $fields = $('input, textarea, select, hidden', this.$form);
        let keys = Array.from(params.keys());

        $fields.each((index, field)=>{
          if(!keys.includes(field.name)){
            let fieldType = this.getFieldType(field);

            if(fieldType === 'radio'){
              if($('[name="' + field.name + '"][value="all"]').length){
                $('[name="' + field.name + '"][value="all"]').prop('checked', true);
              } else {
                $('[name="' + field.name + '"]:checked').prop('checked', false);
              }
            }
            
            if(['text', 'hidden'].includes(fieldType)) {
              $(field).val('');
            }
          }
        })

        for(const element of Array.from(params)) {
          const [key, value] = element;
          const field = $('[name="' + key + '"]');
          const fieldType = this.getFieldType(field[0]);
          if(fieldType === 'radio') {
            $('[value="' + value + '"]').prop('checked', true);
          }
          if(['text', 'hidden'].includes(fieldType)) {
            field.val(value);
          }
        }
      }

      getFieldType(field) {
        if(!field){
          return false;
        }

        return field.tagName === 'INPUT' ? field.type : field.tagName
      }

      clearFilters() {
        let params = new URLSearchParams();
        this.updateUrl(params);
        this.populateForm(params);
        this.ajaxPosts();
        this.updateActiveFilters();
      }

      animateTop() {
        $('html, body').animate({
          scrollTop: this.$node.offset().top - 60
        }, 500);
      }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new Filter(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

(($) => {
    let context = $(document);
 
    Filter.init({
       selector: '[data-ajax-posts]',
    });
 
    $(document).on('ajaxComplete', ()=>{
      Filter.init({
        selector: '[data-ajax-posts="false"]',
        context,
      });
    }) 
})(window.jQuery);