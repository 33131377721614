import Marker from './Marker';
import Geolocate from './Geolocate';
import mapStyles from './map-styles.json';
// import smoothScroll from 'smoothscroll-polyfill';
// import { RSA_PKCS1_OAEP_PADDING } from 'constants';

// smoothScroll.polyfill();

class ProductMap {
    constructor() {

        if(!window.GMaps) {
            return;
        }

        this.startCoords = {
            lat: 39.299236,
            lng: -76.609383,
        };

        try {
            this.map = this.getMap('.js-product-map');
        } catch (e) {
            console.log(e);
            return;
        }

        this.currentQuery = null;
        this.geolocate = new Geolocate;
        this.searchQuery = null;
        this.markerCollection = [];
        this.$searchForm = $('[data-product-map-search-form]');
        this.$searchFormInput = $('[data-product-map-search-form-input]');
        this.$searchFormBtn = $('[data-product-map-search-form-btn]');
        this.$rows = $('[data-location-row]');
        this.$btnReset = $('[data-btn-reset-map]');
        this.$canonicalQuery = $('[data-list-canonical-query]');
        this.activeClass = 'js-is-active';
        this.hiddenClass = 'js-is-hidden';
        this.searchFormBtnOriginalTxt = this.$searchFormBtn.text();
        this.locations = window.productLocations || [];

        this.geolocationData = null;
        this.ipApiData = null;
        this.markersAdded = false;
        
        this.init();
    }

    fitZoom() {
        this.map.fitZoom();

        return this;
    }

    fetchCoordsByQuery(query){
        return $.ajax({
            method: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: {
                action: 'product_map_geocode_lookup',
                params: {
                    address: query,
                },
            },
        });
    }

    onFetchCoordsSuccess(response){
        const result = JSON.parse(response);
        const lat = result.latitude;
        const lng = result.longitude;

        this.map.setCenter(lat, lng);
        
        $('#out_of_area_msg').removeClass('js-is-hidden');
    }  
 
    centerMapOnEnteredAddress(address){
        const results = this.fetchCoordsByQuery(address);

        results.done(this.onFetchCoordsSuccess);
    }

    addMarkers(locations = this.locations) {
        locations.forEach((location) => {
            const marker = new Marker(
                this.map,
                location,
                this.onMarkerClick
            );

            this.markerCollection.push(marker);

            marker.add();
        });

        this.fitZoom();

        return this;
    }

    onSearch(e) {
        e.preventDefault();

        const query = this.$searchFormInput.val();

        if (!query) return;

        this.filterResults(query);

        return this;
    }

    getMap(
        selector,
        lat = this.startCoords.lat,
        lng = this.startCoords.lng
    ) {
        if(!window.GMaps) {
            return;
        }

        const map = new window.GMaps({
            div: selector,
            lat,
            lng,
        });

        map.addStyle({
            styledMapName: 'Curio Wellness',
            styles: mapStyles,
            mapTypeId: 'curio-wellness',
        });

        map.setStyle('curio-wellness');

        return map;
    }

    setSearchInputVal(val) {
        this.searchQuery = val;

        this.$searchFormInput.val(this.searchQuery);

        return this;
    }

    setMarkerVisiblities(postIds) {
        this.map.markers.forEach((m) => {
            const visible = postIds.includes(m.details.post_id);

            m.setVisible(visible);
        });

        return this;
    }

    setRowVisiblities(postIds) {
        this.$rows.toggleClass(this.hiddenClass, true);

        $.each(this.$rows, (index, row) => {
            const $row = $(row);
            const postId = $row.attr('data-post-id');
            const visible = postIds.includes(Number(postId));

            $row.toggleClass(this.hiddenClass, !visible);
        });
    }

    setRowOrderbyDisance(result) {
        this.$rows.attr('data-order', result.length);

        result.forEach( (row, index) =>  {
            const $row = $('[data-location-row]' + '[data-post-id=' + row.post_id + ']');
            $row.attr('data-order', index);
        });

        $('[data-location-row]').sort(function (a, b) {
            return parseInt( $(a).attr('data-order') ) - parseInt( $(b).attr('data-order') );
        })
        .appendTo('.product-map-list__items');   
    }

    setCanonical() {
        this.$canonicalQuery.text(this.currentQuery);

        return this;
    }

    onFilterByZipSuccess(response) {
        const result = JSON.parse(response);
        const postIds = result.map((r) => r.post_id);

        if(!this.markersAdded) {
            this.addMarkers();
        }

        this.setMarkerVisiblities(postIds);
        this.setRowVisiblities(postIds);
        this.setRowOrderbyDisance(result);
        this.setCanonical();

        const markersVisible = this.map.markers.filter((m) => m.visible);

        if (markersVisible.length) {

            $('#out_of_area_msg').addClass('js-is-hidden');
            this.fitZoom();
            
            if( this.map.getZoom() > 8 && markersVisible.length == 1 ){
                this.map.setZoom(15);
            }
        } else {
            this.centerMapOnEnteredAddress(this.currentQuery);
            this.fitZoom();
        }
        
        this.enableSearchField();
    }

    disableSearchField() {
        this.$searchFormBtn
            .attr('disabled', true)
            .text('Loading Results...');

        return this;
    }

    enableSearchField() {
        this.$searchFormBtn
            .removeAttr('disabled')
            .text(this.searchFormBtnOriginalTxt);

        return this;
    }

    filterResults(query = this.searchQuery) {
        if (query === this.currentQuery) return;

        this.disableSearchField();

        this.currentQuery = query;

        const results = this.fetchResultsByQuery(this.currentQuery);

        results.done(this.onFilterByZipSuccess);

        return this;
    }

    fetchResultsByQuery(query) {
        return $.ajax({
            method: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: {
                action: 'product_map_filter_by_zip',
                searchQuery: query,
                distance: 15,
            },
        });
    }

    onGeolocate(result) {

        this
            .setSearchInputVal(result)
            .filterResults();

        return this;
    }

    onMarkerClick(e) {
        const postId = e.details.post_id;

        this.$rows
            .find('[data-location-card]')
            .toggleClass(this.activeClass, false);

        const activeRow = this.$rows
            .filter(`[data-post-id="${postId}"]`);

        activeRow.find('[data-location-card]')
            .toggleClass(this.activeClass, true);

        activeRow[0].scrollIntoView({
            behavior: 'smooth',
        });
    }

    onRowClick(e) {
        const $target = $(e.currentTarget);
        const postId = Number($target.attr('data-post-id'));
        const marker = this.markerCollection.find((m) => (
            (m.config.details.post_id === postId)
        ));

        if (!marker) return;

        marker.onClick(marker.config);
    }

    onBtnResetClick() {
        this.map.markers.forEach((m) => {
            m.setVisible(true);
        });

        this.currentQuery = null;

        this.$rows.toggleClass(this.hiddenClass, false).attr;

        //restore alphabetical sorting
        this.$rows.appendTo('.product-map-list__items');   

        //hide the out of area error message
        $('#out_of_area_msg').addClass('js-is-hidden');

        this.$canonicalQuery.text(
            this.$canonicalQuery.attr('data-default')
        );
        this.$searchFormInput.val(this.currentQuery);

        this.fitZoom();
    }


    requestLocationViaIpApi() {
        // Make an API request to get location via ip-api
        fetch('https://ipapi.co/json/')
            .then(response => response.json())
            .then(data => {

                let state = data.region;
                let missouriAdjacentStates = ['Iowa', 'Illinois', 'Kentucky', 'Tennessee', 'Arkansas', 'Oklahoma', 'Kansas','Nebraska'];
                if(state === 'Missouri' || missouriAdjacentStates.indexOf(state) !== -1) {
                    // debugger;
                    state = "Missouri";
                } else {
                    state = "Maryland";
                }
                this.onGeolocate(state);
            })
            .catch(error => {
                console.error('Error fetching location via ip-api:', error);
            });
    }

    checkAndCallOnGeolocate() {
        // Check if both geolocate and ip-api data are available
        if (this.geolocationData) {
            
        } else if (this.ipApiData) {
            this.onGeolocate(this.ipApiData);
        }
    }

    setEventBindings() {
        this.$searchForm.on('submit', this.onSearch);
        this.$rows.on('click', this.onRowClick);
        this.$btnReset.on('click', this.onBtnResetClick);
    }

    setMethodBindings() {
        this.onSearch = this.onSearch.bind(this);
        this.onGeolocate = this.onGeolocate.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onFilterByZipSuccess = this.onFilterByZipSuccess.bind(this);
        this.onFetchCoordsSuccess = this.onFetchCoordsSuccess.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onBtnResetClick = this.onBtnResetClick.bind(this);
    }

    init() {
        this.setMethodBindings();
        this.setEventBindings();

        
        this.fitZoom();
        // this.geolocate.attempt(result => {
        //     this.geolocationData = true;
        //     this.onGeolocate(result.zipcode);
        // });

        this.requestLocationViaIpApi();
        // debugger;
    }
}

jQuery( document ).ready(function() {
    if(window.GMaps) {
        new ProductMap();
    }
});
