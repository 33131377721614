// import {
//     set,
//     defaultsDeep,
// } from 'lodash';
import defaultConfig from './default-config';
// import InfoWindow from './InfoWindow';

// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code! 
const defaultsDeep = (...args) =>
  args.reverse().reduce((acc, obj) => ({ ...acc, ...obj }), {})

export default class Marker {
    constructor(map, config, clickCb) {
        this.map = map;
        this.config = defaultsDeep(config, defaultConfig);
        this.clickCb = clickCb;
        this.elem = null;
        this.icons = {
            default: '/wp-content/themes/curiowellness/assets/img/icon-google-map-marker-default.png?v=2',
            active: '/wp-content/themes/curiowellness/assets/img/icon-google-map-marker-active.png',
        };
        // this.infoWindow = new InfoWindow(this.map, config);

        this.setAdditionalConfig(this.config);
    }
    
    add() {
        this.elem = this.map.addMarker(this.config);
    }

    onClick(e) {
        const activeMarker = this.map.markers.find((m) => (
            (m.details.active === true)
        ));

        if (activeMarker) {
            activeMarker.setIcon(this.icons.default);
            activeMarker.details.active = false;
        }

        this.elem.setIcon(this.icons.active);
        this.elem.details.active = true;

        this.map.setCenter(
            this.elem.position.lat(),
            this.elem.position.lng()
        );

        this.clickCb(e);
    }

    setAdditionalConfig() {
        // set(this.config, 'infoWindow.content', this.infoWindow.getMarkup());

        this.config['icon'] = this.icons.default;
        this.config['click'] = this.onClick.bind(this);
        
        return this;
    }
}
