// if('wp' in window && 'blocks' in wp) {

//     $styles = [
//         {
//             name: 'brand',
//             label: 'Brand',
//             isDefault: true,
//         },
//         {
//             name: 'brand-alt',
//             label: 'Brand Alt'
//         }
//     ];

//     wp.blocks.registerBlockStyle( 'acf/hero', $styles);
// }


var ppbutton = document.getElementById("hero_video_button");
if(ppbutton) {
    ppbutton.addEventListener("click", playPause);
    playIcon = ppbutton.getElementsByClassName('play')[0];
    pauseIcon = ppbutton.getElementsByClassName('pause')[0];
}

myVideo = document.getElementById("hero_videos");

function playPause() { 
    if(!myVideo) {
        return;
    }
    
    if (myVideo.paused) {
        myVideo.play();
        playIcon.classList.add('hidden');
        pauseIcon.classList.remove('hidden');
        }
    else  {
        myVideo.pause();
        playIcon.classList.remove('hidden');
        pauseIcon.classList.add('hidden');
        }
} 